$(document).ready(function(){
	

	$.validator.addMethod('docNumMatch', function(value, element) {
	   return $('#pif_documentNumber').val() == $('#pif_documentNumber1').val()
	}, 'Document numbers do not match');

	$.validator.addMethod('validDate', function () {
		var mm = $('#birthmonth').val();
		var dd = $('#birthday').val();
		var yyyy = $('#birthyear').val();
		var isleap = (yyyy % 4 == 0 && (yyyy % 100 != 0 || yyyy % 400 == 0));
		if (mm != 'undefined' && dd != 'undefined' && yyyy != 'undefined') {
			if (mm == '04' || mm == '06' || mm == '09' || mm == '11') {
				if (dd > '30') {
					return false;
				}
			}
			if (mm == '02') {
				if (isleap) {
					if (dd > 29) {
						return false;
					}
				} else {
					if (dd > 28) {
						return false;
					}
				}
			}
			return true;
		}
	}, 'Please select a valid date. ');

	$.validator.addMethod('validFutureYear', function(value, element) {
		var currentYear = (new Date()).getFullYear();
		return (value >= currentYear);
	}, 'Please enter a year greater than or equal to '+(new Date()).getFullYear());
	
	$.validator.addMethod('over18', function () {
		var mm = $('#birthmonth').val();
		var dd = $('#birthday').val();
		var yyyy = $('#birthyear').val();
		var today = new Date();
		var oneYear = 1000*60*60*24*365.242199;
		if (mm != 'undefined' && dd != 'undefined' && yyyy != 'undefined') {
			var birthDate = new Date(yyyy, mm - 1, dd);			
			var age = (today.getTime() - birthDate.getTime())/oneYear;
			return ((age - 18) > 0);
		} else {
			console.log('one or more fields have not been completed');
		}
	}, 'You must be 18 or over. ');
	
	$.validator.addMethod('zipFormat', function(value, element) {
		var countryCode = $('#country').val();
		var objRegExp;
		switch (countryCode) {
			case 'US':
				objRegExp  = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
				break;
			case 'MX':
				objRegExp  = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
				break;
			case 'CA':
				objRegExp  = /(^[A-Za-z]\d[A-Za-z](\s|-)?\d[A-Za-z]\d$)/;
				break;
			case 'AU':
				objRegExp  = /(^\d{4}$)/;
				break;
			case 'NZ':
				objRegExp  = /(^\d{4}$)/;
				break;
			default:
				objRegExp  = /^[a-zA-Z0-9\s]+$/;
				break;
		}
		return this.optional(element) || objRegExp.test(value);
	}, 'Invalid zip code. ');
	
	$.validator.addMethod('lettersonly', function(value, element) {
		return this.optional(element) || /^[a-z]+$/i.test(value);
	}, 'Letters only please. ');
	
	$.validator.addMethod('numbersonly', function(value, element) {
		return this.optional(element) || /^[0-9]+$/i.test(value);
	}, 'Numbers only please. ');
	
	$.validator.addMethod('alphanumeric', function(value, element) {
		return this.optional(element) || /^[a-zA-Z0-9]+$/i.test(value);
	}, 'Letters and Numbers only please. ');
	
	$.validator.addMethod('personname', function(value, element) {
		return this.optional(element) || /^[a-z\-.'\s]+$/i.test(value);
	}, 'Letters, hyphens and spaces only please. ');
	
	$.validator.addMethod('letterswithbasicpunc', function(value, element) {
		return this.optional(element) || /^[a-z\-.,()'"\s]+$/i.test(value);
	}, 'Letters or punctuation only please. ');
	
	$.validator.addMethod('numberswithbasicpunc', function(value, element) {
		return this.optional(element) || /^[0-9\-\+.()\s]+$/i.test(value);
	}, 'Please specify a valid phone number. ');

	$.validator.addMethod('numberswithperiodcomma', function(value, element) {
		return this.optional(element) || /^[0-9.,]+$/i.test(value);
	}, 'Please specify a valid number. ');
	
	$.validator.addMethod('phoneformat', function(phone_number, element) {
		var countryCode = $('#country').val();
		if (countryCode == 'US' || countryCode == 'CA') {
			phone_number = phone_number.replace(/\s+/g, "");
			return this.optional(element) || phone_number.length > 9 &&
				phone_number.match(/^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/);
		} else {
			//no format validation for other countries
			return true;
		}
	}, 'Please specify a valid phone number');

	$.validator.addMethod('validname', function(value, element) {
		return this.optional(element) || /^[a-zA-Z\-.'\s]+$/i.test(value);
	}, 'Letters, hyphens, periods, single quotes and spaces only please. ');

	$.validator.addMethod('validaddress', function(value, element) {
		return this.optional(element) || /^[a-zA-Z0-9\s&#,.:'\-\/]*$/i.test(value);
	}, 'Please enter a valid address. ');
	
	$.validator.setDefaults({
		//debug: true,
		errorElement: 'span',
		errorClass: 'invalid',
		errorContainer: $('.validate-msg'),
		focusInvalid: false,
		onkeyup: false,
		ignore: ':hidden, :disabled, .ignore',
		highlight: function (element, errorClass, validClass) {
			if ($(element).closest('fieldset').hasClass('horizontal-fieldset') || $(element).closest('fieldset').hasClass('fieldset-group')) {
				$(element).addClass(errorClass).removeClass(validClass).closest('fieldset').find('legend').addClass(errorClass).removeClass(validClass);
			} else {
				$(element).addClass(errorClass).removeClass(validClass).closest('.form-field').find('label').addClass(errorClass).removeClass(validClass);
			}
		},
		unhighlight: function (element, errorClass, validClass) {
			if ($(element).closest('fieldset').hasClass('horizontal-fieldset') || $(element).closest('fieldset').hasClass('fieldset-group')) {
				$(element).removeClass(errorClass).addClass(validClass);
				if ($(element).closest('fieldset').find('input.'+errorClass).length === 0) {
					$(element).closest('fieldset').find('legend').removeClass(errorClass).addClass(validClass);
				}
			} else {
				$(element).removeClass(errorClass).addClass(validClass).closest('.form-field').find('label').removeClass(errorClass).addClass(validClass);
			}
			$(element.form).find('span[for=' + element.id + ']').remove();
		},
		errorPlacement: function (error, element) {
			error.addClass('form-field-msg');
			//Accessibility changes
			/*error.attr('tabindex',0);*/
			error.attr('role','alert');
			error.attr('aria-invalid',true);
			
			if ($(element).closest('fieldset').hasClass('horizontal-fieldset')) {
				error.insertBefore($(element).closest('fieldset'));
			} else if ($(element).closest('fieldset').hasClass('fieldset-group')) {
				error.appendTo($(element).closest('fieldset').find('legend'));
			} else {
				if ($(element).closest('.form-field').hasClass('horizontal-field')) {
					error.prependTo($(element).closest('.form-field'));
				} else {
					error.appendTo($(element).closest('.form-field'));
				}
			}
			//Divert focus to the invalid field
			$(element).closest('form').find('.invalid:first').parent().find('a,input,select,textarea,button').last().trigger('focus');
		},
		invalidHandler: function (event, validator) {
			var errors = validator.numberOfInvalids(),
			$validateMsg = $(validator.currentForm).find('.validate-msg');
			if (errors) {
				$validateMsg.removeClass('hidden').attr('role', 'alert');
				if ($validateMsg.length > 0) {
					setTimeout(function () {
						$('html,body').stop().animate({scrollTop: ($validateMsg.offset().top)-200 }, 500);
					},250);
				}
				$(validator.currentForm).trigger('form:validate');
			} else {
				$validateMsg.removeClass('hidden').attr('role', 'alert');
			}
		}
	});
});